import React from 'react';
import {graphql} from 'gatsby';
import Products from '../../Components/products/main';

const Maps = ({
  data: {
    allMarkdownRemark: {edges},
  },
}) => {
  return <Products edges={edges} />;
};

export default Maps;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "directions"}}}) {
      edges {
        node {
          html
          frontmatter {
            type
            order
          }
        }
      }
    }
  }
`;
